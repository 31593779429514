.wrap {
  width: 640px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(37, 40, 43, 0.12);
  padding: 30px 80px;
}

.register-wrap {
  width: 640px;
  max-height: 100vh;
  overflow: scroll;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(37, 40, 43, 0.12);
  padding: 30px 80px;
}

.title {
  margin: 0 0 24px 0;
  padding: 0;
  font-size: 30px;
  font-weight: 500;
  color: #232323;
  text-align: center;
}

.navigate-icon {
  width: 20px;
  cursor: pointer;
  margin-bottom: -65px;
}

.register-item-title {
  font-size: 15px;
  /* border-bottom: 1px solid #000; */
  padding-bottom: 10px;
  margin-bottom: 10px;

}

.register-success-win {
  text-align: center;
  padding-top: 60px;
}

.register-success-body {
  margin-bottom: 60px;
}

.register-success-win img {
  display: inline-block;
  width: 30px;
  margin-top: -14px;
}

.register-success-win span {
  font-size: 30px;
  padding-left: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.login-form-line {
  background-color: #eee8e8;
  height: 1px;
  margin-bottom: 20px;
}

.login-field-text-normal,
.login-field-text-error {
  color: #646F79;
  margin: 0;
  padding: 0;
  color: #646F79;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.upload-list {
  display: flex;
  margin-bottom: 10px;
  /* justify-content: ; */
}

.upload-list .upload-item {
  margin-right: 60px;
}

.login-field-text-error {
  color: #D44333;
}

.login-error-text {
  display: block;
  margin-bottom: 24px;
  font-size: 12px;
  color: rgba(212, 67, 51, 1);
  padding-top: 5px;
  height: 12px;
}

.login-input,
.login-input-error,
.login-input-left,
.login-input-left-error {
  width: 480px;
  height: 48px;
  font-size: 14px;
  padding: 14px 16px;
  line-height: 20px;
  margin-top: 6px;
  border: 1px solid #ECEEF0;
  color: #0A0A0A;
  border-radius: 6px;
  outline: none;
}

.login-input-button-wrap {
  position: relative;
}

.login-input-button-wrap input {
  padding-right: 170px;
}

.login-input-button,
.login-input-button-active {
  font-size: 12px;
  padding: 5px;
  position: absolute;
  top: 16px;
  right: 15px;
  display: block;
  cursor: pointer;
  color: #bbb;
}

.login-input-button-active {
  color: #000000 !important;
}

.login-input-error,
.login-input-left-error {
  border-color: #D44333;
}

.login-input::placeholder {
  color: #ABB4BD;
  font-size: 14px;
  font-weight: 400;
}

.forgot-block {
  margin: 0;
  text-align: right;
  color: #0080FF;
  font-size: 14px;
  line-height: 20px;
}

.forgot-block span {
  cursor: pointer;
  float: left;
}

.forgot-block span:last-child {
  float: right;
}

.forgot-block::after {
  content: "";
  display: block;
  clear: both;
}

.login-button,
.login-button-loading {
  display: block;
  border: none;
  background: #0080FF;
  color: #ffffff;
  width: 480px;
  height: 48px;
  border-radius: 6px;
  font-size: 14px;
  margin-top: 14px;
  padding: 0;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}

.submit-button-wrap div:nth-child(1) {
  float: left;
  width: 30%;
  background: #fff;
  color: #999;
  border: 1px solid #999;
}

.submit-button-wrap div:nth-child(2) {
  float: right;
  width: 65%;
}

.login-button-loading span {
  margin-left: 10px;
}

.login-button-loading {
  background: #9dc8f2;
}

.login-other-error {
  font-size: 12px;
  height: 12px;

  color: red;
  text-align: center;
  margin: 0;
  margin-top: -14px;
}



.agreement {
  color: #646F79;
  font-size: 12px;
  text-align: center;
  margin: 24px 0 0;
}


/* page header */
.header {
  width: 100%;
  height: 56px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  background: #FFFFFF;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
}

.header-block {
  width: 1220px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  position: relative;
}

.header-logo {
  width: 126px;
}

.header-controls {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-block .header-help {
  margin: 0;
  padding: 10px;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.header-divider {
  width: 1px;
  height: 20px;
  background: #E9E9E9;
  margin: 0 16px;
}

/* .header-countries, .header-avatar {
  cursor: pointer;
} */
.header-avatar {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.header-notice img {
  width: 32px;
  height: 32px;
}

.header-notice {
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.header-notice span {
  position: absolute;
  font-size: 20px;
  transform: scale(0.5);
  display: inline-block;

  width: 40px;
  height: 40px;
  text-align: center;
  background: red;
  line-height: 40px;
  border-radius: 20px;
  color: #fff;
  right: -15px;
  top: -15px;
}


.avatar-win,
.help-win {
  position: absolute;
  box-shadow: 0px 4px 3px 2px rgb(0 0 0 / 2%);
  background: #fff;
  list-style: none;
  top: 50px;
  right: -25px;
}

.help-win {
  right: 75px;
  width: 185px;
}

.avatar-win {
  width: 230px;
  right: -104px;
}

.avatar-win li,
.help-win li {
  padding: 8px 10px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  display: block;
  word-break: break-all;
}

.avatar-win li.align-left {
  text-align: left;
}

.avatar-win li:hover,
.help-win li:hover {
  background: #f8f5f5;
}

.win-li-icon {
  width: 15px;
  margin-left: 10px;
  margin-top: -3px;
}

.help-win .help-win-info p {
  margin: 0;
  font-size: 12px;
  text-align: left;
}

.help-win p.help-win-title {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.help-win li:first-child {
  border-bottom: 1px solid #f8efef;
}

.login-case-top {
  margin-top: -16px;
}


.register-progress-container {
  margin-top: 50px;
  width: 640px;
  height: 60px;
}

.register-progress-container .progress-d {
  width: 90%;
  margin: auto;
  height: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px rgb(37 40 43 / 12%);
  overflow: hidden;
}

.register-progress-container .progress-d .child {
  background-color: #0080FF;
  height: 100%;
  width: 0;
  transition: width .5s ease;
}

.register-progress-container nav {
  list-style: none;
  width: 90%;
  padding-top: 10px;
  margin: auto;
}

.register-progress-container li {
  display: inline-block;
  width: 33%;
  text-align: center;
  font-size: 14px;
  /* background-color: #0A0A0A; */
}