.upload-component {
  width: 150px;
  height: 210px;
}

.upload-component input,
.multiple-upload-component input {
  display: none;
}

.multiple-upload-component {
  margin-bottom: 30px;
}

.multiple-upload-component .picture-list-wrap {
  overflow-x: scroll;
  padding-bottom: 10px;
}

.multiple-upload-component .picture-list-wrap::-webkit-scrollbar {
  display: block;
}

.multiple-upload-component .picture-list-wrap::-webkit-scrollbar-thumb {
  /* 滚动条的其他样式定制，注意，这个一定也要定制，否则就是一个透明的滚动条 */
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, .4);
  background-color: rgba(0, 0, 0, .5);
}

.multiple-upload-component .picture-list-wrap::-webkit-scrollbar:horizontal {
  /* 设置水平滚动条厚度 */
  height: 4px;
}

.multiple-upload-component .picture-wrap {
  /* flex: 1; */
  flex-shrink: 0;
  margin-right: 60px;
}

.picture-upload {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  cursor: pointer;
}

.picture-close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.upload-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.picture-user {
  width: 100%;
}

.picture-wrap {
  width: 150px;
  height: 150px;
  background: #eee;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.picture-error {
  font-size: 14px;
  color: red;
  display: block;
}

.picture-list-wrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.upload-title {
  /* text-align: center; */
  font-size: 12px;
  margin: 0;
  color: #646F79;
  padding-bottom: 10px;
}

.upload-alert {
  /* text-align: center; */
  padding-left: 5px;
  font-size: 12px;
  color: red;
  margin: 0;
  padding-top: 5px;
}

.picture-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}